import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';

const Datenschutz = () => (
    <Layout>
        <SEO title="Datenschutz" />
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Datenschutz</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="simple-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">
                            <strong>
                                D A T E N S C H U T Z E R K L Ä R U N G<br />
                            </strong>
                            <strong>Bauer &amp; Dr. Pauly Rechtsanwälte</strong>
                        </h1>
                        <p className="text-center">
                            <strong>&nbsp;</strong>
                        </p>
                        <p className="text-center">
                            <strong>
                                TEIL I<br />
                                Allgemeiner Teil:
                            </strong>
                        </p>
                        <p className="text-center">
                            <strong>
                                Wahrung Ihrer Privatsphäre sowie
                                <br />
                                Namen und Kontaktdaten der Datenschutzverantwortlichen
                            </strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <ol>
                            <li>
                                <strong>
                                    WAHRUNG IHRER PRIVATSPÄHRE DURCH DIE
                                    <br />
                                    Rechtsanwälte Bauer &amp; Dr. Pauly
                                </strong>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>
                            Wir freuen uns über Ihren Besuch auf unserer Webseite sowie deren Nutzung und Ihr damit verbundenes Interesse an unserem
                            Leistungsspektrum. Dabei ist uns der Schutz Ihrer personenbezogener Daten ein besonders wichtiges Anliegen. Wir verarbeiten
                            personenbezogene Daten, die unsererseits erhoben werden, vertraulich und gemäß den gesetzlichen Bestimmungen. Datenschutz und
                            Informationssicherheit sind ein wesentlicher Bestandteil unserer Unternehmensphilosophie. In den nachfolgenden Datenschutzhinweisen
                            zeigen wir auf, wie Ihre personenbezogenen Daten erhoben werden, insbesondre für welche Zwecke und auf welcher Rechtsgrundlage Ihre
                            personenbezogenen Daten erhoben werden und welche Rechte sowie Ansprüche Sie haben.
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                            <li>
                                <strong>
                                    NAME UND KONTAKTDATEN DES FÜR DIE DATENVERARBEITUNG
                                    <br />
                                    VERANTWORTLICHEN
                                </strong>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>2.1</p>
                        <p>
                            Dienstanbieter gemäß § 13 TMG und Verantwortlicher für die Verarbeitung Ihrer Daten entsprechend der Datenschutz-Grundverordnung
                            („DSGVO“), insbesondere gemäß Art. 4 Abs. 7 DSGVO ist die
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <strong>
                                Bauer &amp; Dr. Pauly Rechtsanwälte
                                <br />
                            </strong>
                            <strong>
                                Rechtsanwalt Holger Bauer
                                <br />
                            </strong>
                            <strong>
                                Furtbachstr. 10A
                                <br />
                            </strong>
                            <strong>
                                Tel.: &nbsp;&nbsp;&nbsp; +49 (0) 711 46 98 777
                                <br />
                            </strong>
                            <strong>
                                E-Mail: <a href="mailto:kanzlei">kanzlei</a>@bauerpauly.de
                            </strong>
                        </p>
                        <p>
                            <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                        </p>
                        <p>2.2</p>
                        <p>
                            Zur Geltendmachung Ihrer Rechte, Meldungen von Datenschutzverstößen und Beschwerden betreffend der Verarbeitung Ihrer
                            personenbezogenen Daten sowie dem Widerruf Ihrer Einwilligung raten wir Ihnen an, dass Sie sich an folgende Adresse wenden:
                            <a href="mailto:datenschutz@bauerpauly.de">
                                <strong>datenschutz@bauerpauly.de</strong>
                            </a>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p className="text-center">
                            <strong>TEIL II</strong>
                        </p>
                        <p className="text-center">
                            <strong>Datenschutzhinweise für Betrieb und Nutzung unserer Webseite</strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <ol>
                            <li>
                                <strong> INFORMATIONEN ÜBER DIE ERHEBUNG PERSONENBEZOGENER DATEN </strong>
                            </li>
                        </ol>
                        <p>1.1</p>
                        <p>
                            Bei Ihrer Kontaktaufnahme mit uns via E-Mail oder über ein Kontaktformular werden die von Ihnen bekanntgegebenen Daten (insbesondere
                            Ihre Kontaktdaten, namentlich Vor-/Nachname, Adressanschrift, Telefon-/Faxnummer und E-Mail-Adresse) von uns gespeichert, um Ihr
                            Anliegen bearbeiten zu können und gegebenenfalls in Kontakt mit Ihnen zu treten. Die uns insoweit bekanntgewordenen Daten löschen
                            wir, sobald die Speicherung dieser Daten nicht mehr erforderlich ist oder aber schränken die Verarbeitung ein, falls gesetzliche
                            Aufbewahrungspflichten bestehen.
                        </p>
                        <p>1.2.</p>
                        <p>
                            Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke
                            nutzen möchten, werden wir Sie im Folgenden über die jeweiligen Vorgänge informieren.
                        </p>
                        <p>&nbsp;</p>
                        <ol start="2">
                            <li>
                                <strong> IHRE BETROFFENENRECHTE </strong>
                            </li>
                        </ol>
                        <p>Sie haben das Recht:</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                gemäß Art.&nbsp;15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie
                                Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen
                                Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
                                Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese
                                nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und
                                ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
                                personenbezogenen Daten zu verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
                                Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung,
                                aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                                ist;
                            </li>
                            <li>
                                gemäß Art.&nbsp;18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit
                                der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht
                                mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß
                                Art.&nbsp;21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                            </li>
                            <li>
                                gemäß Art.&nbsp;20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                                maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;7 Abs.&nbsp;3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge,
                                dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
                            </li>
                            <li>
                                gemäß Art.&nbsp;77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
                                Aufsichtsbehörde Ihres üblichen Aufenthaltsortes wenden
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="3">
                            <li>
                                <strong> IHR WIDERRUFS-/WIDERSPRUCHSRECHT</strong>
                            </li>
                        </ol>
                        <p>
                            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet
                            werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit
                            dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
                            letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.
                        </p>
                        <p>
                            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an:
                            <a href="mailto:kanzlei@bauerpauly.de">
                                <strong>kanzlei@bauerpauly.de</strong>
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <ol start="4">
                            <li>
                                <strong>
                                    ERHEBUNG UND SPEICHERUNG PERSONENBEZOGENER DATEN BEI
                                    <br />
                                    NUTZUNG UNSERER WEBSEITE
                                </strong>
                            </li>
                        </ol>
                        <p>4.1</p>
                        <p>
                            Bei Aufruf und Nutzung unserer Webseite werden automatisch personenbezogene Daten von Ihrem Browser an unseren Server übermittelt.
                            Folgende Daten werden erfasst und bis zur automatisierten Löschung vorübergehend in einem sog. Logfile gespeichert:
                        </p>
                        <ul>
                            <li>Ihre IP-Adresse des anfragenden Rechners</li>
                            <li>Datum nebst Uhrzeit des Zugriffs</li>
                            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                            <li>Name und URL der abgerufenen Datei</li>
                            <li>Inhalt der Anforderung (konkrete Seite)</li>
                            <li>Zugriffsstatus/HTTP-Statuscode</li>
                            <li>jeweils übertragene Datenmenge</li>
                            <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                            <li>Ihr Browser</li>
                            <li>Ihr Betriebssystem, dessen Oberfläche sowie Name Ihres Access-Providers</li>
                            <li>Sprache und Version der Browsersoftware</li>
                        </ul>
                        <p>4.2</p>
                        <p>Die genannten Daten werden von uns zu nachstehenden Zwecken verarbeitet:</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>Gewährleistung eines reibungslosen Verbindungsaufbaus unserer Webseite,</li>
                            <li>Gewährleistung einer komfortablen Nutzung unserer Webseite,</li>
                            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                            <li>zu weiteren administrativen Zwecken.</li>
                        </ul>
                        <p>4.3</p>
                        <p>
                            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus vorbezeichnet
                            aufgelisteten Zwecken zur Datenerhebung. Wir setzen bei Benutzung unserer Webseite Cookies sowie Analysedienste ein. Nähere
                            Erläuterungen dazu erhalten Sie nachstehend.
                        </p>
                        <p>4.4</p>
                        <p>
                            Sollten Sie Fragen jeglicher Art haben, können Sie uns jederzeit, insbesondere über das auf unserer Webseite zur Verfügung gestellte
                            Kontaktformular kontaktieren. Dabei ist die Angabe Ihrer E-Mail-Adresse erforderlich, damit wir Kenntnis darüber haben, von wem die
                            Anfrage stammt, um diese auch entsprechend beantworten zu können. Weitere Angaben können freiwillig getätigt werden. Die
                            Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
                            erteilten Einwilligung. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich
                            ist oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
                        </p>
                        <p>&nbsp;</p>
                        <ol start="5">
                            <li>
                                <strong> WEITERGABE VON DATEN AN DRITTE </strong>
                            </li>
                        </ol>
                        <p>
                            Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir
                            geben Ihre persönlichen Daten lediglich an Dritte weiter, wenn:
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            –&nbsp; &nbsp; Sie Ihre nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO ausdrückliche Einwilligung dazu
                            <br />
                            erteilt haben,
                        </p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                die Weitergabe nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der
                                Nichtweitergabe Ihrer Daten haben,
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                für den Fall, dass für die Weitergabe nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;c DSGVO eine gesetzliche Verpflichtung
                                besteht sowie
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                dies gesetzlich zulässig und nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;b DSGVO für die Abwicklung von
                                Vertragsverhältnissen mit Ihnen erforderlich ist.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="6">
                            <li>
                                <strong> COOKIES </strong>
                            </li>
                        </ol>
                        <p>6.1</p>
                        <p>
                            Wir setzen auf unserer Webseite Cookies ein. Dabei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und auf
                            Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen. Cookies richten auf Ihrem
                            Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
                        </p>
                        <p>6.2</p>
                        <p>
                            In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
                            bedeutet jedoch nicht, dass wir dadurch unmittelbar positive Kenntnis von Ihrer Identität erhalten.
                        </p>
                        <p>6.3</p>
                        <p>
                            Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. Daher setzen wir
                            sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Webseite bereits besucht haben. Diese werden nach
                            Verlassen unserer Seite automatisch gelöscht.
                        </p>
                        <p>6.4</p>
                        <p>
                            Darüber hinaus setzen wir zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten
                            Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen oder nutzen Sie unsere Webseite erneut, um unsere Dienste in Anspruch zu
                            nehmen, wird automatisch erkannt, dass Sie bereits auf unserer Webseite waren und welche Eingaben sowie Einstellungen Sie getätigt
                            haben, um diese nicht erneut eingeben zu müssen.
                        </p>
                        <p>6.5</p>
                        <p>
                            Zum anderen setzten wir Cookies ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres
                            Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch oder Nutzung unserer Webseite automatisch
                            zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
                        </p>
                        <p>6.6</p>
                        <p>
                            Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der von
                            Dritten nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO erforderlich.
                        </p>
                        <p>6.7</p>
                        <p>
                            Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch dergestalt konfigurieren, dass keine Cookies
                            auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige
                            Deaktivierung von Cookies kann allerdings dazu führen, dass Sie nicht alle Funktionen unserer Webseite nutzen können.
                        </p>
                        <p>&nbsp;</p>
                        <ol start="7">
                            <li>
                                <strong> ANALYSE- UND TRACKING-TOOLS </strong>
                            </li>
                        </ol>
                        <p>
                            Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1
                            lit.&nbsp;f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die
                            fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer
                            Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als
                            berechtigt im Sinne des Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO zu qualifizieren.
                        </p>
                        <p>Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.</p>
                        <p>
                            <strong>
                                <br />
                                7.1 Google Analytics
                            </strong>
                        </p>
                        <p>
                            Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Webseite nutzen wir Google Analytics, ein
                            Webanalysedienst der Google Inc. (<a href="https://www.google.de/%20intl/de/about/">https://www.google.de/ intl/de/about/</a>) (1600
                            Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte
                            Nutzungsprofile erstellt und Cookies verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite wie
                        </p>
                        <ul>
                            <li>Browser-Typ/-Version,</li>
                            <li>verwendetes Betriebssystem,</li>
                            <li>Referrer-URL (die zuvor besuchte Seite),</li>
                            <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                            <li>Uhrzeit der Serveranfrage,</li>
                        </ul>
                        <p>
                            werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der
                            Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Webseitennutzung und der
                            Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung unserer Webseite zu
                            erbringen. Diese Informationen werden gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit
                            Dritte diese Daten im Auftrag verarbeiten. Ihre IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die IP-Adressen
                            werden anonymisiert, damit eine Zuordnung ausgeschlossen ist (IP-Masking). Sie können die Installation der Cookies durch eine
                            entsprechende Einstellung der Browser-Software verhindern. Wir weisen allerdings darauf hin, dass in diesem Fall gegebenenfalls
                            einzelne Funktionen dieser Webseite nicht vollständig genutzt werden können. Sie können darüber hinaus die Erfassung der durch das
                            Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten sowie die Verarbeitung dieser Daten durch Google verhindern,
                            indem Sie ein Browser-Add-on herunterladen und installieren (https://tools.google.com/dlpage/gaoptout?hl=de). Alternativ zum
                            Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics außerdem dadurch
                            verhindern, indem Sie auf diesen Link klicken. Ein Opt-out-Cookie wird gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch
                            dieser Webseite verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Webseite und wird auf Ihrem Gerät
                            abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen. Weitere Informationen zum
                            Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (
                            <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>).
                        </p>
                        <p>
                            Diese Webseite verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
                            weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
                            Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
                        </p>
                        <p>&nbsp;</p>
                        <p>Rechtsgrundlage für die Nutzung von Google Analytics ist&nbsp;Art.&nbsp;6 Abs.&nbsp;1 S. 1 lit. f DSGVO.</p>
                        <p>&nbsp;</p>
                        <p>
                            <strong>7.2 Google Adwords Conversion-Tracking</strong>
                        </p>
                        <p>
                            Um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unserer Webseite für Sie auszuwerten, nutzen
                            wir das Google Conversion-Tracking. Dabei wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt, sofern Sie über eine
                            Google-Anzeige auf unsere Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
                            persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Webseite des Adwords-Kunden und das Cookie ist noch nicht
                            abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet
                            wurde. Datenschutzbehörden verlangen für den zulässigen Einsatz von Google Analytics den Abschluss einer
                            Auftragsdatenverarbeitungs-Vereinbarung. Eine entsprechende Vorlage wird unter&nbsp;
                            <a href="http://www.google.com/analytics/terms/de.pdf">http://www.google.com/analytics/terms/de.pdf</a>&nbsp;von Google angeboten.
                            Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden.
                            Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen,
                            die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige
                            geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
                            Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten,
                            können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen
                            von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so
                            einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Googles Datenschutzbelehrung zum
                            Conversion-Tracking finden Sie hier (
                            <a href="https://services.google.com/sitestats/de.html">https://services.google.com/sitestats/de.html</a>).
                        </p>
                        <p>Informationen des Drittanbieters:</p>
                        <p>Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.</p>
                        <p>
                            Nutzerbedingungen:&nbsp;
                            <u>
                                <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>
                            </u>
                            , Übersicht zum Datenschutz:&nbsp;
                            <u>
                                <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">
                                    http://www.google.com/intl/de/analytics/learn/privacy.html
                                </a>
                            </u>
                            sowie die Datenschutzerklärung:&nbsp;
                            <u>
                                <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>
                            </u>
                            .
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <strong>7.3 Google Adwords&nbsp;Conversion-Tracking</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>7.3.1</p>
                        <p>
                            Wir nutzen das Angebot von Google Adwords, um mithilfe von Werbemitteln (sog. Google Adwords) auf externen Webseiten auf unsere
                            attraktiven Angebote aufmerksam zu machen. Wir können in Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die
                            einzelnen Werbemaßnahmen sind. Wir verfolgen insoweit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, unsere
                            Webseite für Sie interessanter zu gestalten und eine faire Berechnung von Werbekosten zu erreichen.
                        </p>
                        <p>&nbsp;</p>
                        <p>7.3.2</p>
                        <p>
                            Diese Werbemittel werden durch Google über sogenannte „Ad Server“ ausgeliefert. Dazu nutzen wir Ad Server Cookies, durch die
                            bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden können. Sofern
                            Sie über eine Google-Anzeige auf unsere Webseite gelangen, wird von Google Adwords ein Cookie in ihrem PC gespeichert. Diese Cookies
                            verlieren in der Regel nach 30&nbsp;Tagen ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem
                            Cookie werden in der Regel als Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency), letzte
                            Impression (relevant für Post-View-Conversions) sowie Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen
                            werden möchte) gespeichert.
                        </p>
                        <p>&nbsp;</p>
                        <p>7.3.3</p>
                        <p>
                            Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite eines
                            Adwords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde
                            erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jedem Adwords-Kunden wird ein
                            anderes Cookie zugeordnet. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir selbst erheben
                            und verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google lediglich statistische
                            Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen
                            besonders effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer
                            nicht anhand dieser Informationen identifizieren.
                        </p>
                        <p>&nbsp;</p>
                        <p>7.3.4</p>
                        <p>
                            Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir
                            haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Google erhoben
                            werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von AdWords Conversion erhält Google die
                            Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben.
                            Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei
                            Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung
                            bringt und speichert.
                        </p>
                        <p>&nbsp;</p>
                        <p>7.3.5</p>
                        <p>Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern, und zwar durch:</p>
                        <ul>
                            <li>
                                eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie
                                keine Anzeigen von Drittanbietern erhalten;
                            </li>
                            <li>
                                Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain
                                „www.googleadservices.com“ blockiert werden, <a href="https://www.google.de/settings/ads">https://www.google.de/settings/ads</a>
                                , wobei diese Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen;
                            </li>
                            <li>
                                Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den
                                Link <a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird,
                                wenn Sie Ihre Cookies löschen;
                            </li>
                            <li>
                                dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link
                                <a href="http://www.google.com/settings/ads/plugin">http://www.google.com/settings/ads/plugin</a>.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                            Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollständig nutzen können.
                        </p>
                        <p>&nbsp;</p>
                        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist&nbsp;Art.&nbsp;6 Abs.&nbsp;1 S. 1 lit. f DSGVO.</p>
                        <p>&nbsp;</p>
                        <p>
                            Weitere Informationen zum Datenschutz bei Google finden Sie hier:
                            <a href="http://www.google.com/intl/de/policies/privacy">http://www.google.com/intl/de/policies/privacy</a> und
                            <a href="https://services.google.com/sitestats/de.html">https://services.google.com/sitestats/de.html</a>. Alternativ können Sie die
                            Webseite der Network Advertising Initiative (NAI) unter
                            <a href="http://www.networkadvertising.org">http://www.networkadvertising.org</a>&nbsp;besuchen. Google hat sich dem EU-US Privacy
                            Shield unterworfen,&nbsp;
                            <u>
                                <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>
                            </u>
                            .
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>7.4. Google Tag Manager</strong>
                        </p>
                        <p>
                            Diese Webseite verwendet den Google Tag Manager. Dadurch können JavaScript-Tags&nbsp;über eine Oberfläche verwaltet werden. Der
                            Google Tool Manager implementiert lediglich Tags, sodass keine Cookies eingesetzt und personenbezogenen Daten nicht erfasst werden.
                            Der Google Tool Manager löst andere Tags aus, die wiederum gegebenenfalls Daten erfassen. Google Tag Manager greift nicht auf diese
                            Daten zu. Über den Google Tag Manager ist auch die IP-Anonymisierung für Google Analytics gesetzt und aktiv.
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <ol start="8">
                            <li>
                                <strong> SOCIAL MEDIA PLUGINS</strong>
                            </li>
                        </ol>
                        <p>
                            Wir setzen auf unserer Webseite auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO Social Plugins des sozialen Netzwerks Facebook, um
                            unser Unternehmen hierüber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der
                            DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. Die
                            Einbindung dieser Plugins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode, um Nutzer unserer Webseite bestmöglich zu
                            schützen.
                        </p>
                        <p>
                            Auf unserer Webseite kommen Social-Media Plugins von Facebook zum Einsatz, um deren Nutzung persönlicher zu gestalten. Hierfür
                            nutzen wir den „LIKE“ oder „TEILEN“-Button. Dabei handelt es sich um ein Angebot von Facebook. Wenn Sie eine Seite unseres
                            Webauftritts aufrufen, die ein solches Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook auf.
                            Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Durch die
                            Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen
                            hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschließlich
                            Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt und dort gespeichert. Sind Sie
                            bei Facebook eingeloggt, kann Facebook den Besuch unserer Webseite Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den Plugins
                            interagieren, zum Beispiel den „LIKE“ oder „TEILEN“-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen
                            Server von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und Ihren
                            Facebook-Freunden angezeigt. Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten
                            Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.B. um
                            Ihre Nutzung unserer Webseite im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere
                            Facebook-Nutzer über Ihre Aktivitäten auf unserer Webseite zu informieren und um weitere mit der Nutzung von Facebook verbundene
                            Dienstleistungen zu erbringen. Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem
                            Facebook-Konto zuordnet, müssen Sie sich vor Ihrem Besuch unserer Webseite bei Facebook ausloggen. Zweck und Umfang der
                            Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und
                            Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen (
                            <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>) von Facebook.
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <ol start="9">
                            <li>
                                <strong> DATENSICHERHEIT</strong>
                            </li>
                        </ol>
                        <p>
                            Wir verwenden innerhalb Ihres Besuchs und Nutzung unserer Webseite das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung
                            mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine
                            256 Bit Verschlüsselung. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der
                            geschlossenen Darstellung des Schüssel-/Schlosssymbols in der Statusleiste Ihres Browsers.
                        </p>
                        <p>
                            Wir bedienen uns überdies geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder
                            vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
                            schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technischen Entwicklung fortlaufend verbessert.
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="10">
                            <li>
                                <strong> AKTUALITÄT UND ÄNDERUNG DIESER DATENSCHUTZERKLÄRUNG</strong>
                            </li>
                        </ol>
                        <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.</p>
                        <p>
                            Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
                            Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.&nbsp;Die jeweils aktuelle Datenschutzerklärung kann
                            jederzeit auf der Webseite von Ihnen abgerufen und ausgedruckt werden.
                        </p>
                        <p>&nbsp;</p>
                        <p className="text-center">
                            <strong>TEIL III</strong>
                        </p>
                        <p className="text-center">
                            <strong>Hinweise zur Datenverarbeitung für unsere Kunden </strong>
                        </p>
                        <p className="text-center">
                            <strong>gem. Art 12 f. DSGVO</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol>
                            <li>
                                <strong>
                                    NAMEN UND KONTAKDATEN DES FÜR DIE DATENVERARBEITUNG
                                    <br />
                                    VERANTWORTLICHEN
                                </strong>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>1.1</p>
                        <p>
                            Dienstanbieter gemäß § 13 TMG und Verantwortlicher für die Verarbeitung Ihrer Daten entsprechend der Datenschutz-Grundverordnung
                            („DSGVO“), insbesondere gemäß Art. 4 Abs. 7 DSGVO ist die
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <strong>
                                Bauer &amp; Dr. Pauly Rechtsanwälte
                                <br />
                            </strong>
                            <strong>
                                Rechtsanwalt Holger Bauer
                                <br />
                            </strong>
                            <strong>
                                Furtbachstr. 10A
                                <br />
                            </strong>
                            <strong>
                                Tel.: &nbsp;&nbsp;&nbsp; +49 (0) 711 46 98 777
                                <br />
                            </strong>
                            <strong>
                                E-Mail: <a href="mailto:kanzlei">kanzlei</a>@bauerpauly.de
                            </strong>
                        </p>
                        <p>
                            <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                        </p>
                        <p>1.2</p>
                        <p>
                            Zur Geltendmachung Ihrer Rechte, Meldungen von Datenschutzverstößen und Rügen betreffend der Verarbeitung Ihrer personenbezogenen
                            Daten sowie dem Widerruf Ihrer Einwilligung raten wir an, dass Sie sich an folgende Adresse wenden:
                            <a href="mailto:datenschutz@bauerpauly.de">
                                <strong>datenschutz@bauerpauly.de</strong>
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <ol start="2">
                            <li>
                                <strong>
                                    ERHEBUNG UND SPEICHERUNG PEROSNENBEZOGENER DATEN SOWIE
                                    <br />
                                    ART UND ZWECK UND DEREN VERWENDUNG
                                </strong>
                            </li>
                        </ol>
                        <p>2.1</p>
                        <p>Wir beraten Verbraucher, Unternehmen und Unternehmer. Wenn Sie uns beauftragen, erheben wir folgende Informationen:</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>Anrede, Vor-/Nachname</li>
                            <li>Ihre E-Mail-Adresse,</li>
                            <li>Ihre Anschrift,</li>
                            <li>Ihre Telefon- (namentlich Festnetz und Mobilfunknummer) sowie Faxnummer,</li>
                            <li>Informationen, die für unsere Beauftragung und Bearbeitung Ihres Auftrags notwendig sind</li>
                        </ul>
                        <p>2.2</p>
                        <p>Die Erhebung dieser Daten erfolgt,</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>um Sie als unseren Kunden identifizieren zu können,</li>
                            <li>um Sie angemessen beraten können,</li>
                            <li>zur Führung der Korrespondenz mit Ihnen,</li>
                            <li>zur Rechnungsstellung und</li>
                            <li>zur Abwicklung von gegebenenfalls bestehenden Haftungsansprüchen sowie der Geltendmachung möglicher Ansprüche gegen Sie.</li>
                        </ul>
                        <p>2.3</p>
                        <p>
                            Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;b DSGVO zu den genannten
                            Zwecken für die angemessene Bearbeitung des Ihrerseits erteilten Auftrags und für die beidseitige Erfüllung von Verpflichtungen aus
                            dem Vertragsverhältnis erforderlich.
                        </p>
                        <p>2.4</p>
                        <p>
                            Die für die Beauftragung von uns erhobenen personenbezogenen Daten werden bis zum Ablauf der gesetzlichen Aufbewahrungspflicht
                            gespeichert und danach gelöscht, es sei denn, dass wir nach Artikel&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;c DSGVO aufgrund von
                            steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder AO) zu einer längeren Speicherung
                            verpflichtet sind oder Sie in eine darüber hinausgehende Speicherung nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO
                            eingewilligt haben.
                        </p>
                        <p>&nbsp;</p>
                        <ol start="3">
                            <li>
                                <strong> WEITERGABE VON DATEN AN DRITTE </strong>
                            </li>
                        </ol>
                        <p>
                            Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Soweit
                            dies nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich
                            ist, werden Ihre personenbezogenen Daten an Dritte weitergegeben. Die weitergegebenen Daten dürfen von dem Dritten ausschließlich zu
                            den genannten Zwecken verwendet werden.
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="4">
                            <li>
                                <strong> IHRE BETROFFENENRECHTE </strong>
                            </li>
                        </ol>
                        <p>Sie haben das Recht:</p>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                gemäß Art.&nbsp;15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie
                                Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen
                                Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
                                Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese
                                nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und
                                ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
                                personenbezogenen Daten zu verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
                                Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung,
                                aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                                ist;
                            </li>
                            <li>
                                gemäß Art.&nbsp;18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit
                                der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht
                                mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß
                                Art.&nbsp;21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                            </li>
                            <li>
                                gemäß Art.&nbsp;20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                                maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                            </li>
                            <li>
                                gemäß Art.&nbsp;7 Abs.&nbsp;3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge,
                                dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
                            </li>
                            <li>
                                gemäß Art.&nbsp;77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
                                Aufsichtsbehörde Ihres üblichen Aufenthaltsortes wenden.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ol start="5">
                            <li>
                                <strong> IHR WIDERSPRUCHSRECHT</strong>
                            </li>
                        </ol>
                        <p>
                            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f
                            DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art.&nbsp;21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
                            Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben.
                        </p>
                        <p>Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an</p>
                        <p>kanzlei@bauerpauly.de</p>
                        <p>&nbsp;</p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <ol start="6">
                            <li>
                                <strong> WEITERLEITUNG AN MITARBEITER AUF SEITE DES AUFTRAGGEBERS </strong>
                            </li>
                        </ol>
                        <p>
                            Es erfolgt keine gesonderte Information jedes Mitarbeiters eines Auftraggebers, mit dem wir Kontakt haben und dessen Daten wir
                            speichern. Dies kann insbesondere Vor-/Nachname und E-Mail-Adresse betreffen. Der Auftraggeber wird mit Zustandekommen eines
                            Vertragsverhältnisses mit uns die vorgenannten Hinweise zum Datenschutz bzw. zur Datenverarbeitung an alle betroffenen Mitarbeiter
                            weiterleiten, mit denen wir im Rahmen unserer Beauftragung kommunizieren.
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default Datenschutz;
